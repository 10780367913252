import { createStyles, Grid, Stack } from '@mantine/core';
import { UserCard } from './components/UserCard';
import { PersonalInformationCard } from './components/PersonalInformationCard';
import AddressCard from './components/AddressCard';
import MeetingMinutesCard from './components/MeetingMinutesCard';
import { useRecoilValue } from 'recoil';
import { organizationSettings } from '../../../../Atoms/settings';
import UserSettingSwitch from './components/UserSettingSwitch';

const useStyles = createStyles(() => ({
	outerContainer: {
		overflowY: 'auto',
	},
}));

export const MyProfile = () => {
	const { classes } = useStyles();
	const { sharing } = useRecoilValue(organizationSettings);

	return (
		<div>
			<Grid>
				<Grid.Col span={3}>
					<Stack p={'md'} h={'100%'} className={classes.outerContainer}>
						<UserCard />
					</Stack>
				</Grid.Col>
				<Grid.Col span={9}>
					<Stack p={'md'} h={'100%'} className={classes.outerContainer}>
						<PersonalInformationCard />
						<AddressCard />
						<MeetingMinutesCard />
						{!sharing ? null : (
							<UserSettingSwitch
								settingKey='sharing'
								label='Sharing Default'
								description='Enable this setting to have new meetings created with sharing preference set to'
								successMessage='You have successfully changed the sharing setting.'
								failureMessage='Sorry, there was an error changing the sharing setting. Please try again.'
								sharingOptionText='Anyone with Link'
								defaultOptionText='Invite only'
							/>
						)}
						<UserSettingSwitch
							settingKey='rollup_email'
							label='Daily Meeting Rollup Email'
							description="Enable this setting to receive a daily email at 4 PM with a list of tomorrow's upcoming meetings where Reelay is present."
							successMessage='You have successfully changed the daily meeting rollup email setting.'
							failureMessage='Sorry, there was an error changing the daily meeting rollup email setting. Please try again.'
						/>
					</Stack>
				</Grid.Col>
			</Grid>
		</div>
	);
};

export default MyProfile;
